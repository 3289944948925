<template>
  <el-card shadow="hover" header="" class="x-card-title-slot">
    <div class="row no-gutters align-items-center" slot="header">
      <div class="col header-title">站点信息</div>

      <el-button type="primary" @click="handleSaveShopData('ruleForm')" size="small">
        保存
      </el-button>
    </div>

    <el-form :model="ruleForm" ref="ruleForm" label-width="170px" class="demo-ruleForm" :rules="rules">
      <el-form-item label="站点名称" prop="shop_name">
        <el-input v-model="ruleForm.shop_name" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="站点网址" prop="region">
        <span>https://{{ ruleForm.domain_name + $url }}</span>
      </el-form-item>
      <el-form-item label="创建时间">
        <span>
          {{ ruleForm.shop_createtime | unixToDate("yyyy-MM-dd hh:mm") }}
        </span>
      </el-form-item>
      <el-form-item label="客服电话" prop="customer_service">
        <el-input v-model="ruleForm.customer_service" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="默认地区" prop="regionValue">
        <x-address-select :value.sync="ruleForm.regionValue" width="300px" />
      </el-form-item>

      <el-form-item label="商城开关设置" class="shop-status-warper">

        <table class="switch-table">
          <thead>
            <th>商城状态</th>
            <th>是否显示市场价</th>
            <th>是否显示礼包单品销售价</th>
            <th>是否开启手动换货功能</th>
            <th>是否显示购买须知</th>
          </thead>
          <tbody>
            <td>
              <el-switch v-model="ruleForm.shop_disable"></el-switch>
            </td>
            <td>
              <el-switch v-model="ruleForm.show_price"></el-switch>
            </td>
            <td>
              <el-switch v-model="ruleForm.goods_sale_price_enable"></el-switch>
            </td>
            <td>
              <el-switch v-model="ruleForm.manual_exchange_goods"></el-switch>
            </td>
            <td>
              <el-switch v-model="ruleForm.show_purchasing_notice_switch"></el-switch>
            </td>
          </tbody>
        </table>

        <table class="switch-table">
          <thead>
            <th>是否显示分类导航</th>
            <th>是否显示三级分类</th>
            <th>是否开通售后功能</th>
            <th>是否允许新用户注册</th>
            <el-tooltip effect="dark" placement="top">
              <th>是否显示套餐收藏导航<i class="el-icon-warning-outline"></i></th>
              <div slot="content">
                开启后，移动端前台可显示套餐礼包收藏的导航按钮，礼包列表及礼包详情中可显示出礼包收藏的功能。
              </div>
            </el-tooltip>
          </thead>
          <tbody>
            <td>
              <el-switch v-model="ruleForm.category_button"></el-switch>
            </td>
            <td>
              <el-switch v-model="ruleForm.third_category_button"></el-switch>
            </td>
            <td>
              <el-switch v-model="ruleForm.enable_aftersale"></el-switch>
            </td>
            <td>
              <el-switch v-model="ruleForm.allow_member_new_register"></el-switch>
            </td>
            <td>
              <el-switch v-model="ruleForm.shop_combo_collect"></el-switch>
            </td>
          </tbody>
        </table>
        <table class="switch-table">
          <thead>
            <el-tooltip effect="dark" placement="top">
              <th>是否显示购物车导航<i class="el-icon-warning-outline"></i></th>
              <div slot="content">
                关闭后，移动端前台将不再显示购物车导航；商品列表页及详情页中也不再显示加入购物车的功能。
              </div>
            </el-tooltip>
            <el-tooltip effect="dark" placement="top">
              <th>是否允许微信支付<i class="el-icon-warning-outline"></i></th>
              <div slot="content">
                关闭后，移动端前台将不允许使用微信进行下单支付。
              </div>
            </el-tooltip>
            <th></th>
            <th></th>
            <th></th>
          </thead>
          <tbody>
            <td>
              <el-switch v-model="ruleForm.shop_cart"></el-switch>
            </td>
            <td>
              <el-switch v-model="ruleForm.shop_wechat_pay"></el-switch>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tbody>
        </table>
      </el-form-item>
      <el-form-item label="商城登录方式">
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="手机号登录"></el-checkbox>
          <el-checkbox label="账号登录"></el-checkbox>
          <el-checkbox label="卡密登录"></el-checkbox>
        </el-checkbox-group>
        <span style="position: absolute;top:0;left:326px">提示：暂不支持【账号登录+卡密登录】作为移动端的登录方式。</span>
      </el-form-item>

      <el-form-item label="不登录是否允许浏览商城">
        <el-switch v-model="ruleForm.no_login_allow_browse"
          style="margin-right: 40px"></el-switch>提示：开启后，移动端用户可在不登录的情况下，正常浏览商品列表及商品详情。
        <br />
      </el-form-item>
      <el-form-item prop="no_login_allow_time" v-if="ruleForm.no_login_allow_browse">
        请设置开关自动关闭的时间：<el-select size="mini" v-model="ruleForm.no_login_allow_time" placeholder="请选择">
          <el-option v-for="item in automaticShutdown" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否开通在线客服">
        <el-switch v-model="ruleForm.customer_service_show"
          style="margin-right: 40px"></el-switch>提示：开通后，商城将显示“在线客服”按钮，并跳转至指定客服链接。
      </el-form-item>
      <el-form-item label="用户收货地址设置">
        <div class="address-setting" style="height: 40px; display: flex;align-items: center;">
          <div style="display: flex;align-items: center; margin-right: 30px;">
            <el-radio v-model="ruleForm.shop_address_off" :label="0">用户自定义</el-radio>
            <el-tooltip class="item" effect="dark" content="用户可在前台自由添加收货地址信息。" placement="top">
              <i style="font-size: 16px;position: relative;top: -1px;" class="el-icon-question"></i>
            </el-tooltip>
          </div>
          <div style="display: flex;align-items: center;">
            <el-radio v-model="ruleForm.shop_address_off" :label="1">后台指定地址</el-radio>
            <el-tooltip class="item" effect="dark" content="用户仅允许使用后台指定的地址作为收货地址。" placement="top">
              <i style="font-size: 16px;position: relative;top: -1px;" class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </div>
        <div v-if="ruleForm.shop_address_off">
          <el-form :model="addrForm" :rules="addrRules" ref="addrForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="收货地址" prop="shop_address_do" class="area-select">
              <en-region-picker :api="MixinRegionApi" :default="areas" @changed="handleChangeArea"></en-region-picker>
            </el-form-item>
            <el-form-item label="详细地址" prop="addr">
              <div style="width: 400px;">
                <el-input v-model.trim="addrForm.addr" :maxlength="100" placeholder="请填写100字以内的详细地址"></el-input>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-form-item>
      <el-form-item v-show="ruleForm.customer_service_show" label="客服跳转链接" prop="customer_service_link">
        <el-input v-model="ruleForm.customer_service_link" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="商城货币单位设置" prop="monetary_unit">
        <el-input v-model="ruleForm.monetary_unit" style="width: 300px">
          <template slot="prepend">
            <span style="margin-right: 20px">￥</span>
          </template>
          <template slot="prepend">转换为</template>
        </el-input>
      </el-form-item>
      <el-form-item v-if="ruleForm.monetary_unit" label="货币转换比率" prop="conversion_ratio" style="margin-bottom: 22px;">
        <el-input v-model="ruleForm.conversion_ratio" style="width: 300px"
          oninput="value = (value.match(/**大于零的正整数 */ /^[1-9]\d*$/) || [''])[0]">
          <template slot="prepend">
            <span style="margin-right: 20px">1 ：</span>
          </template>
          <!-- <template slot="prepend">转换为</template> -->
        </el-input>
      </el-form-item>
      <el-form-item label="设置登录页背景图片" prop="background_img">
        <el-upload v-show="!ruleForm.background_img" ref="uploadFile" style="display: flex"
          :action="`${MixinUploadApi}?scene=goods`" list-type="picture-card" :limit="1" :before-upload="beforeUpload"
          :on-success="handleUploadSuccess">
          <i class="el-icon-plus"></i>
          <template slot="tip">
            <div style="line-height: 148px">
              请上传16：9，大小不超10MB，格式为jpg/png的图片。
            </div>
          </template>
        </el-upload>
        <div class="background-img" v-if="ruleForm.background_img">
          <img :src="ruleForm.background_img" alt="" />
          <div class="mantle">
            <i class="el-icon-delete" @click="handleUploadRemove"></i>
            <el-button round size="mini" class="btn-change-img" @click="handleChangeImg">更换图片</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="设置登录页按钮颜色" prop="btn_color">
        <el-button type="primary" size="mini" @click="ruleForm.btn_color = '#FFFFFF'">重置</el-button>
        <el-color-picker v-model="ruleForm.btn_color" popper-class="set-background-colo-picker" />
      </el-form-item>

      <el-form-item label="网页版权声明" prop="copyright">
        <el-input v-model="ruleForm.copyright" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="" prop="name">
        <div style="line-height: 1.7">
          <span>此信息将显示在网页底部</span><br />
          <span style="padding-top: 15px; color: gray">
            {{ ruleForm.copyright }}
          </span>
        </div>
      </el-form-item>
      <el-form-item label="商城备注信息">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 7 }" maxlength="100" :rows="2"
          placeholder="请输入100字以内的备注信息" v-model="ruleForm.shop_remark" style="width: 300px"></el-input>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import * as API_account from "@/api/account";
import XAddressSelect from "../../components/x-address-select/x-address-select";
import { getCnpcShopIds } from "@/api/account"
export default {
  name: "person",
  components: { XAddressSelect },
  data () {
    return {
      // 地区id数组
      areas: [],
      ispetroChina: false,//是否是中石油商城
      params: {
        shop_id: undefined,
        shop_name: undefined,
        domain_name: undefined,
        shop_createtime: undefined,
        customer_service: undefined,
        copyright: undefined,
        monetary_unit: undefined,
        region: undefined,
        shop_disable: undefined,
        show_price: undefined,
        goods_sale_price_enable: undefined,
        manual_exchange_goods: undefined,
        show_purchasing_notice_switch: undefined,
        category_button: undefined,
        third_category_button: undefined,
        enable_aftersale: undefined,
        allow_member_new_register: undefined,
        shop_combo_collect: undefined,//礼包收藏
        shop_cart: undefined,//购物车
        shop_wechat_pay: undefined,//微信支付
        shop_remark: undefined,
        no_login_allow_browse: undefined
      },
      checkList: ["手机号登录", "账号登录", "卡密登录"],//登录方式
      //自动关闭的数组
      automaticShutdown: [{
        value: 0,
        label: '从不'
      }],
      ruleForm: {
        shop_id: undefined,
        shop_name: undefined,
        domain_name: undefined,
        shop_createtime: undefined,
        customer_service: undefined,
        copyright: undefined,
        monetary_unit: undefined,
        category_button: undefined,
        customer_service_show: undefined,
        customer_service_link: undefined,
        shop_disable: undefined,
        show_price: undefined,
        goods_sale_price_enable: undefined,
        manual_exchange_goods: undefined,
        show_purchasing_notice_switch: undefined,
        category_button: undefined,
        third_category_button: undefined,
        enable_aftersale: undefined,
        allow_member_new_register: false,
        shop_combo_collect: undefined,//礼包收藏
        shop_cart: undefined,//购物车
        shop_wechat_pay: undefined,//微信支付
        regionValue: [],
        // task 691新加
        background_img: "",
        btn_color: undefined,
        third_category_button: false,
        shop_remark: undefined,
        no_login_allow_browse: undefined,
        no_login_allow_time: 0,
        conversion_ratio: undefined, // 货币转换比率
        shop_address_off: 0, // 企业地址权限 关闭1开启
        shop_address_do: { addr: '' },
        addr: ''
      },
      addrForm: {
        addr: '',
        shop_address_do: {}
      },
      addrRules: {
        addr: [{
          required: true,
          message: '请填写详细地址',
          trigger: 'blur'
        }],
        shop_address_do: [
          {
            required: true,
            validator: (value, rules, callback) => {

              if (this.addrForm.shop_address_do.province) {
                callback()
              } else {
                callback(new Error('请选择联系地址'))
              }
            }
          }
        ]
      },
      rules: {
        // 目前要求非必填
        // regionValue: [
        //   {
        //     required: true,
        //     validator: (rule, value, callback) => {
        //
        //       if (!value || value.length === 0) {
        //         return callback('请选择默认地址');
        //       }
        //
        //       callback();
        //     },
        //     trigger: "blur",
        //   },
        // ],
        shop_name: [
          {
            required: true,
            message: "请输入站点名称",
            trigger: "blur",
          },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        customer_service: [
          {
            // required: true,

            validator: (rule, value, callback) => {
              // let reg = /^(((\d{3,4}-)?[0-9]{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/;
              // if (value && !reg.test(value)) {
              //   return callback('座机号或手机号格式不对');
              // }
              callback();
            },
            trigger: "blur"
          }
        ],
        copyright: [
          {
            required: true,
            message: "请输入网页版权声明",
            trigger: "blur",
          },
        ],
        no_login_allow_time: [
          {
            required: true,
            message: "请设置自动关闭时间",
            trigger: "change",
          },
        ],
        monetary_unit: [
          {
            min: 1,
            max: 2,
            message: "字数不超2个字符",
            trigger: "blur",
          },
        ],
        customer_service_link: [
          {
            required: true,
            message: "开通在线客服功能，必须要填写客服跳转链接",
            trigger: "blur",
          },
        ],
        conversion_ratio: [{
          required: true,
          message: "请输入货币转换比率",
          trigger: "blur",
        }],
      },
    };
  },
  watch: {
    "ruleForm.customer_service_show" () {
      this.rules.customer_service_link[0].required =
        this.ruleForm.customer_service_show;
    },
    "checkList" (newl, old) {// 可以三种都选中 
      if (this.checkList.length == 2) {//只有两种时 卡密和账号不能同时存在
        if (this.checkList.some((item) => item == "手机号登录") == false) {
          this.checkList = old
        }
      } else if (this.checkList.length == 0) {//必须选中最少一种登录方式
        this.checkList = old
      }

    }
  },
  async created () {
    let shopidList = await getCnpcShopIds()
    shopidList = shopidList.data.split(',')
    this.ispetroChina = shopidList.includes((this.$store.getters.shopInfo.shop_id).toString())
    this.getShopMsg();
    for (let i = 1; i <= 24; i++) {
      this.automaticShutdown.push({ value: i, label: `${i}小时后` })
    }
  },
  methods: {
    /** 操作地区选择器改变时 触发*/
    handleChangeArea (val) {
      console.log(val);
      Object.assign(this.addrForm.shop_address_do, val.regions)
    },
    handleSwitch (e, name) {
      this.ruleForm[name] = e;
      this.$set(this, "ruleForm", { ...this.ruleForm })
    },
    //获取n小时后的日期  时间
    accessTimeInAnHour () {
      let startTime = Date.parse(new Date()) / 1000
      // 当前时间戳+3600s（一小时，其他时间通过计算时间戳进行相应加减），重新设置 Date 对象
      let endTime = startTime + (3600 * this.ruleForm.no_login_allow_time)
      return [startTime, endTime];
    },
    //保存
    async handleSaveShopData (formName) {
      this.params = {};

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.params.shop_address_off = this.ruleForm.shop_address_off
          this.params.shop_id = this.ruleForm.shop_id;
          this.params.shop_name = this.ruleForm.shop_name;
          this.params.customer_service = this.ruleForm.customer_service;
          this.params.show_price = this.ruleForm.show_price ? 1 : 0;
          this.params.show_mktprice = this.params.show_price
          this.params.conversion_ratio = this.ruleForm.monetary_unit ? this.ruleForm.conversion_ratio : 1;
          // this.params.enable_aftersale = this.ruleForm.enable_aftersale ? 1 : 0;
          // this.params.show_purchasing_notice_switch = this.ruleForm
          //   .show_purchasing_notice_switch
          //   ? 1
          //   : 0;
          let buyer_login_type = [];
          this.checkList.some(item => item == "手机号登录") ? buyer_login_type.push(1) : null
          this.checkList.some(item => item == "账号登录") ? buyer_login_type.push(2) : null
          this.checkList.some(item => item == "卡密登录") ? buyer_login_type.push(3) : null
          this.params.buyer_login_type = buyer_login_type.toString();
          this.params.copyright = this.ruleForm.copyright;
          this.params.shop_remark = this.ruleForm.shop_remark;
          //不登录是否允许浏览商城
          this.params.no_login_allow_browse = this.ruleForm.no_login_allow_browse ? 1 : 0;
          if (this.ruleForm.no_login_allow_browse) {
            //设置自动关闭时间
            if (this.ruleForm.no_login_allow_time == 0) {
              this.params.no_login_allow_create_time = this.accessTimeInAnHour()[0]
              this.params.no_login_allow_end_time = 0
            } else {
              this.params.no_login_allow_create_time = this.accessTimeInAnHour()[0]
              this.params.no_login_allow_end_time = this.accessTimeInAnHour()[1]
            }

          }
          this.params.monetary_unit = this.ruleForm.monetary_unit;
          // this.params.category_button = this.ruleForm.category_button ? 1 : 0;
          // this.params.third_category_button = this.ruleForm
          //   .third_category_button
          //   ? 1
          //   : 0;
          this.params.customer_service_show = this.ruleForm
            .customer_service_show
            ? 1
            : 0;
          this.params.customer_service_link =
            this.ruleForm.customer_service_link;
          this.params.btn_color = this.ruleForm.btn_color;
          this.params.background_img = this.ruleForm.background_img;

          if (this.ruleForm.regionValue) {
            this.params.region =
              this.ruleForm.regionValue[this.ruleForm.regionValue.length - 1];
          }

          this.params.shop_disable = this.ruleForm.shop_disable
            ? "OPEN"
            : "CLOSED";
          this.params.shop_combo_collect = this.ruleForm.shop_combo_collect
            ? "OPEN"
            : "CLOSE";
          this.params.shop_cart = this.ruleForm.shop_cart
            ? "OPEN"
            : "CLOSE";
          this.params.shop_wechat_pay = this.ruleForm.shop_wechat_pay
            ? "OPEN"
            : "CLOSE";
          let swKeys = [
            "goods_sale_price_enable",
            "manual_exchange_goods",
            "show_purchasing_notice_switch",
            "category_button",
            "third_category_button",
            "enable_aftersale",
            "allow_member_new_register",
          ]
          swKeys.forEach(key => {
            this.params[key] = +this.ruleForm[key]
          })
          if (this.ruleForm.shop_address_off) {
            const res = await this.$refs.addrForm.validate()
            console.log('保存', res);
            if (res) {
              this.ruleForm.shop_address_do = { ...this.addrForm.shop_address_do, addr: this.addrForm.addr }
              this.params.shop_address_do = this.ruleForm.shop_address_do
              this.params.shop_address_do.county = this.addrForm.shop_address_do.region
              this.params.shop_address_do.county_id = this.addrForm.shop_address_do.region_id
              const map = ['province', 'city', 'county', 'town']
              map.forEach(k => map.push(`${k}_id`))
              Object.entries(this.params.shop_address_do).forEach(([k, v]) => {
                if (map.includes(k)) this.params.shop_address_do[`jd_${k}`] = v
                if (!v) {
                  if (k === 'town') this.params.shop_address_do[k] = ''
                  if (k === 'town_id') this.params.shop_address_do[k] = 0
                }
              })

            } else {
              this.params.shop_address_do = undefined
            }
          }
          API_account.editShopSetting(this.params).then((res) => {
            this.getShopMsg();
            this.$message.success("操作成功");
          });
        } else {
          return false;
        }
      });
    },
    //查询
    getShopMsg () {
      API_account.getShopmsg().then((res) => {
        if (res.buyer_login_type) {
          let arr = res.buyer_login_type.split(',')
          this.checkList = []
          arr.some(item => item == 1) ? this.checkList.push("手机号登录") : null
          arr.some(item => item == 2) ? this.checkList.push("账号登录") : null
          arr.some(item => item == 3) ? this.checkList.push("卡密登录") : null
        }
        Object.keys(this.ruleForm).forEach((key) => {
          let value = res[key];

          if (key === "customer_service_show") {
            value = res.customer_service_show === 1;
          }
          if (key === "category_button") {
            value = res.category_button === 1;
          }

          // 移动端三级分类按钮权限
          if (key === "third_category_button") {
            value = res.third_category_button == 1;
          }

          if (key === "show_price") {
            value = res.show_mktprice === 1;
          }

          if (key === "no_login_allow_browse") {
            value = res.no_login_allow_browse === 1;
          }
          if (key === "show_mktprice") {
            value = res.show_price === 1;
          }

          if (key === "enable_aftersale") {
            value = res.enable_aftersale === 1;
          }

          if (key === "show_purchasing_notice_switch") {
            value = res.show_purchasing_notice_switch === 1;
          }

          if (key === "shop_disable") {
            value = res.shop_disable === "OPEN";
          }
          if (key === "shop_combo_collect") {
            value = res.shop_combo_collect === "OPEN";
          }
          if (key === "shop_cart") {
            value = res.shop_cart === "OPEN";
          }
          if (key === "shop_wechat_pay") {
            value = res.shop_wechat_pay === "OPEN";
          }

          let swKeys = ["show_mktprice",
            "goods_sale_price_enable",
            "manual_exchange_goods",
            "show_purchasing_notice_switch",
            "category_button",
            "third_category_button",
            "enable_aftersale",
            "allow_member_new_register"
          ]
          if (swKeys.includes(key)) {
            value = res[key] === 1
          }

          if (!value && key === "regionValue") {
            if (res.web_shop_address) {
              value = [
                res.web_shop_address.province_id,
                res.web_shop_address.city_id,
                res.web_shop_address.county_id,
                res.web_shop_address.town_id,
              ].filter((id) => !!id);
            }
          }

          this.ruleForm[key] = value;
        });
        if (this.ruleForm.no_login_allow_browse == false) {
          this.ruleForm.no_login_allow_time = 0
        }
        this.ruleForm = {
          ...this.ruleForm,
        };
        console.log("this.ruleForm", this.ruleForm);
        this.$forceUpdate();
        // 如果有后台指定地址
        if (res.shop_address_do) {
          const { addr, ...arg } = res.shop_address_do
          this.addrForm.addr = addr
          this.addrForm.shop_address_do = arg
          this.areas = [arg.province_id, arg.city_id, arg.county_id, arg.town_id].filter(Boolean)
        }
      });
    },
    // 上传图片
    // 上传之前校验
    beforeUpload (file) {
      let result = true;
      const fileType = file.name.split(".")[1];
      const fileSize = parseFloat((file.size / 1024 / 1024).toFixed(2));
      if (fileType !== "jpg" && fileType !== "png") {
        result = false;
      }
      if (fileSize > 10) {
        result = false;
      }
      if (!result) {
        this.$message.error("请按照提示信息上传图片");
        return false;
      }
    },
    // 上传成功回调
    handleUploadSuccess (response, file, fileList) {
      this.ruleForm.background_img = response.url;
    },
    // 删除文件
    handleUploadRemove () {
      this.ruleForm.background_img = "";
      this.$refs.uploadFile.clearFiles();
    },
    // 更换图片
    handleChangeImg () {
      this.$refs.uploadFile.clearFiles();
      this.$refs.uploadFile.$children[1].$refs.input.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.address-setting {
  /deep/ .el-radio {
    margin: 0;
  }
}

/deep/ .app-address-title {
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
}

/deep/ .app-address-title {
  width: 400px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}

/deep/ .app-address-title:hover {
  height: 40px;
  line-height: 40px;
}

/deep/ .el-form-item__content {
  .background-img {
    width: 167px;
    height: 167px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    .mantle {
      width: 167px;
      height: 167px;
      display: none;
      position: absolute;
      top: 0;
      background-color: black;
      opacity: 0.5;
      border-radius: 10px;

      .el-icon-delete {
        font-size: 23px;
        position: relative;
        left: 85%;
      }

      .btn-change-img {
        display: block;
        margin: 0 auto;
        position: relative;
        top: 15%;
        background-color: black;
        opacity: 0.6;
        color: white;
      }
    }
  }

  .background-img:hover {
    .mantle {
      display: block;
    }
  }

  .el-color-picker {
    height: 30px;
    margin-left: 10px;
    vertical-align: middle;

    .el-color-picker__trigger {
      width: 90px;
      height: 30px;
      padding: 0;
      border: 0;
      border-radius: 5px;
    }

    .el-color-picker__color {
      border: 1px solid #cccccc;
    }

    .el-icon-arrow-down:before {
      content: "";
    }
  }
}

.content {
  background-color: white;
  border-radius: 10px;
  padding-bottom: 20px;
}

/deep/ .el-form-item {
  margin-bottom: 15px;
}

.zz {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}

.shu {
  height: 30px;
  width: 6px;
  background-color: rgb(26, 67, 169);
  display: inline-block;
}

.he {
  padding-top: 30px;
  padding-bottom: 30px;
}

.shop-status-warper {
  .switch-table {
    width: 100%;
    text-align: center;
    border: 1px solid rgb(146, 147, 149);

    &:not(:last-of-type) {
      border-bottom: 0;
    }

    th {
      padding: 5px 5px;
      width: 20%;
      background-color: rgb(26, 67, 169);
      color: #fff;
      border-color: rgb(165, 185, 238);
      border-right: 1px solid rgb(165, 185, 238);
      border-bottom: 0;

      &:last-of-type {
        border-right: 0;
      }
    }

    td {
      border-right: 1px solid rgb(146, 147, 149);
      border-bottom: 0;

      &:last-of-type {
        border-right: 0;
      }
    }
  }

}
</style>
